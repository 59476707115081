import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const RatingStars = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
`;

export const RatingBox = styled.div<{ $isSlug?: boolean; $isHeader?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 15px;
  & > span {
    ${text.FS16}
    line-height: 24px;
    &:nth-child(2) {
      font-weight: ${(props) => props.theme.weights.bold};
    }
    &:nth-child(3) {
      margin-left: -5px;
      color: ${(props) => props.theme.colors.grayText};
    }
  }

  ${(props) => {
    if (props.$isSlug) {
      return `
          margin-top: 10px;
          & > span {
            font-size: 20px;
            line-height: 29px;
          }
        ${RatingStars} {
          & svg {
            width: 20px;
            height: 20px;
          }
        }
        `;
    }
  }}
  ${(props) => {
    if (props.$isHeader) {
      return `
          margin-top: 0px;
        `;
    }
  }}

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    ${(props) => {
      if (props.$isSlug) {
        return `
          & > span {
            font-size: 16px;
            line-height: 24px;
          }
        `;
      }
    }}
  }
`;
