import styled from 'styled-components';
import { Link } from 'gatsby';

import * as text from '@/components/general/TextStyles';

export const FooterBox = styled.footer`
  position: relative;
  z-index: 2;
  margin-top: 130px;
  font-weight: ${(props) => props.theme.weights.regular};
  background-color: ${(props) => props.theme.colors.secondaryBlack};

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 100px;
    height: auto;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 73px;
  padding-bottom: 25px;
  ${text.FS14}
  color: ${(props) => props.theme.colors.offWhite1};
`;

export const MainBox = styled.div`
  display: flex;
  column-gap: 90px;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    column-gap: 30px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    flex-direction: column;
    row-gap: 50px;
  }
`;

export const LogoBox = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: 200px;
  }
`;

export const LogoDescription = styled.p`
  ${text.FS17}
  color: ${(props) => props.theme.colors.grayText};
  width: 214px;
  margin-top: 14px;
  margin-right: -20px;

  @media (max-width: ${(props) => props.theme.breakpoints.xxl}px) {
    width: 350px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: 214px;
  }
`;

export const ColumnsFullWidthRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    width: auto;
    margin-right: 50px;
  }
  @media (max-width: 850px) {
    margin-right: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    justify-content: flex-start;
  }
`;

export const ColumnsRow = styled.div`
  display: flex;
  column-gap: 90px;
  margin-bottom: 70px;

  @media (max-width: ${(props) => props.theme.breakpoints.xxl}px) {
    display: grid;
    grid-template-columns: 290px 290px;
    grid-template-rows: auto auto;
    grid-auto-flow: row;
    row-gap: 60px;
    column-gap: 0px;
    margin-bottom: 50px;

    & > div:first-child {
      grid-column: span 2;
    }
    & > div:last-child {
      margin-left: 15px;

      @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
        margin-left: 20px;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
        margin-left: 15px;
      }
      @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
        margin-left: 0px;
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    grid-template-columns: 260px 260px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ColumnTitle = styled.h5`
  ${text.FS17}
  font-weight: ${(props) => props.theme.weights.bold};
  color: ${(props) => props.theme.colors.white};
  white-space: nowrap;
`;

export const ColumnText = styled.span`
  background-color: transparent;
  transition: ${(props) => props.theme.transition.primary};
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 10px;
`;

export const ColumnLinksBox = styled.div<{ $isFeatures?: boolean }>`
  display: flex;
  flex-direction: column;
  max-height: 270px;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 10px;
  margin-top: 25px;
  width: ${(props) => (props.$isFeatures ? '540px' : 'auto')};
  margin-left: ${(props) => (props.$isFeatures ? '0px' : '-10px')};

  & a {
    line-height: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 8px;

    &:hover {
      ${ColumnText} {
        background-color: #353547;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    column-gap: 20px;
    width: ${(props) => (props.$isFeatures ? '520px' : 'auto')};

    & a {
      &:hover {
        ${ColumnText} {
          background-color: transparent;
        }
      }
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    column-gap: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: auto;
    max-height: none;
  }
`;

export const ColumnLink = styled(Link)``;

export const ColumnIconBox = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid #535365;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const SocialsTitle = styled.p`
  ${text.FS17}
  color: ${(props) => props.theme.colors.white};
  font-weight: ${(props) => props.theme.weights.bold};
  margin-bottom: 25px;

  @media (max-width: ${(props) => props.theme.breakpoints.xl}px) {
    font-size: 17px;
  }
`;

export const SocialsBox = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 40px;

  & > a {
    display: flex;
    align-items: center;
    column-gap: 7.5px;
    line-height: 100%;
  }
`;

export const Caption = styled.small`
  color: ${(props) => props.theme.colors.grayText};
  ${text.FS14}
`;

export const ShortFooterBox = styled.footer`
  margin-top: ${(props) => props.theme.spacing.xl2}px;
  padding-bottom: ${(props) => props.theme.spacing.s1}px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    margin-top: 60px;
    padding-bottom: 40px;
  }
`;

export const ShortCaption = styled.div`
  ${text.FS14}
  color: ${(props) => props.theme.colors.grayText};
  text-align: center;
`;
