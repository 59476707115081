/* eslint-disable */
import React, { useRef, useEffect } from 'react';

import logo from '@/images/Footer/logo.svg';

import * as styled from './Footer.styled';

import Container from '../Container';
import { SOCIALS, COLUMNS } from './constants';
import { Icon, Image } from '../general';
import { checkIfExternalLink } from '@/utils/functions';

export enum FooterVariants {
  FULL = 'full',
  SHORT = 'short',
}

interface IFooter {
  variant?: FooterVariants;
}

export const Footer: React.FC<IFooter> = ({
  variant = FooterVariants.FULL,
}) => {
  const year = new Date().getFullYear();

  const linksColumnsRef = useRef([]);

  useEffect(() => {
    linksColumnsRef.current = linksColumnsRef.current.slice(0, COLUMNS.length);
  }, [COLUMNS]);

  switch (variant) {
    case FooterVariants.FULL:
      return (
        <styled.FooterBox>
          <Container>
            <styled.Content>
              <styled.MainBox>
                <styled.LogoBox>
                  <Image src={logo} width="30px" height="30px" />
                  <styled.LogoDescription>
                    Launch apps in record time with a visual development
                    low-code platform
                  </styled.LogoDescription>
                </styled.LogoBox>
                <styled.ColumnsFullWidthRow>
                  <div>
                    <styled.ColumnsRow>
                      {COLUMNS.map((column) => (
                        <div key={column.title}>
                          <styled.ColumnTitle>
                            {column.title}
                          </styled.ColumnTitle>
                          <styled.ColumnLinksBox
                            $isFeatures={column.title === 'Features'}
                          >
                            {column.links.map((link) => {
                              switch (link.to.includes('mailto')) {
                                case false:
                                  const isExternalLink = checkIfExternalLink(
                                    link.to,
                                  );
                                  return (
                                    <styled.ColumnLink
                                      key={link.text}
                                      to={isExternalLink ? undefined : link.to}
                                      href={
                                        isExternalLink ? link.to : undefined
                                      }
                                      as={isExternalLink ? 'a' : undefined}
                                    >
                                      {link.iconName && (
                                        <styled.ColumnIconBox>
                                          <Icon
                                            name={link.iconName}
                                            color="#C1C1D3"
                                          />
                                        </styled.ColumnIconBox>
                                      )}
                                      <styled.ColumnText>
                                        {link.text}
                                      </styled.ColumnText>
                                    </styled.ColumnLink>
                                  );
                                case true:
                                  return (
                                    <a key={link.text} href={link.to}>
                                      {link.iconName && (
                                        <styled.ColumnIconBox>
                                          <Icon
                                            name={link.iconName}
                                            color="#C1C1D3"
                                          />
                                        </styled.ColumnIconBox>
                                      )}
                                      <styled.ColumnText>
                                        {link.text}
                                      </styled.ColumnText>
                                    </a>
                                  );
                              }
                            })}
                          </styled.ColumnLinksBox>
                        </div>
                      ))}
                    </styled.ColumnsRow>
                    <div>
                      <styled.SocialsTitle>
                        Subscribe to our channels
                      </styled.SocialsTitle>
                      <styled.SocialsBox>
                        {SOCIALS.map((social) => (
                          <a
                            key={social.text}
                            href={social.href}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon name={social.iconName} />
                            <span>{social.text}</span>
                          </a>
                        ))}
                      </styled.SocialsBox>
                      <styled.Caption>
                        © {year} Code Platform, Unipessoal LDA
                      </styled.Caption>
                    </div>
                  </div>
                </styled.ColumnsFullWidthRow>
              </styled.MainBox>
            </styled.Content>
          </Container>
        </styled.FooterBox>
      );
    case FooterVariants.SHORT:
      return (
        <styled.ShortFooterBox>
          <Container>
            <styled.ShortCaption>
              © {year} Code Platform, Unipessoal LDA
            </styled.ShortCaption>
          </Container>
        </styled.ShortFooterBox>
      );
    default:
      return null;
  }
};
