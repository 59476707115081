import React from 'react';
import { graphql } from 'gatsby';

import { ThemeProvider } from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyle';
import Wrapper from '@/components/Wrapper';
import { Header } from '@/components/Header';
import { ExpertSlugContent } from '@/components/Experts/ExpertSlug';
import { Footer } from '@/components/Footer';
import { Links } from '@/utils/constants';
import { Meta } from '@/components/general/Meta';
import { removeSpaces } from '@/utils/functions';

const ExpertSlug = ({ pageContext, data }) => (
  <ThemeProvider>
    <Meta
      title={`${pageContext.pageTitle} | CodePlatform`}
      description={data.contentfulExpert.description.description}
      image={data.contentfulExpert.avatar.url}
      url={`${Links.EXPERTS}/${removeSpaces(pageContext.pageTitle)}`}
    />
    <GlobalStyle />
    <Wrapper $overflowNotHidden>
      <Header />
      <ExpertSlugContent expert={data.contentfulExpert} />
      <Footer />
    </Wrapper>
  </ThemeProvider>
);

export default ExpertSlug;

export const query = graphql`
  query ExpertQuery($pageId: String) {
    contentfulExpert(id: { eq: $pageId }) {
      id
      companyName
      role
      agency
      avatar {
        url
        gatsbyImageData(width: 120, placeholder: BLURRED)
      }
      background {
        gatsbyImageData(width: 706, placeholder: BLURRED)
      }
      shortDescription
      gallery {
        title
        gatsbyImageData(placeholder: BLURRED)
      }
      description {
        raw
      }
      reviews {
        title
        rating
        date
        review {
          review
        }
        author
      }
      websiteLink
    }
  }
`;
