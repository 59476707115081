/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { Card } from '@/components/Experts/components/Card';
import {
  Breadcrumbs,
  Button,
  ButtonColors,
  CustomSlider,
  Icon,
  Layout,
  Name,
} from '@/components/general';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { Links, LocalKeys } from '@/utils/constants';
import { Rating } from '../components';
import { IExpertInfo } from '../types';
import { options } from './Options';

import * as styled from './Content.styled';

interface IExpertSlugContent {
  expert: IExpertInfo;
}

export const ExpertSlugContent: React.FC<IExpertSlugContent> = ({ expert }) => {
  const expertDescription = renderRichText(expert.description, options);
  const [galleryModal, setGalleryModal] = useState<boolean>(false);

  const sliderBoxRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<any>(null);

  useOnClickOutside(sliderBoxRef, () => setGalleryModal(false));

  useEffect(() => {
    if (galleryModal) {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.code === 'ArrowRight') {
          sliderRef?.current?.slickNext();
        } else if (e.code === 'ArrowLeft') {
          sliderRef?.current?.slickPrev();
        } else if (e.code === 'Escape') {
          setGalleryModal(false);
        }
      };
      document.addEventListener('keydown', onKeyDown);
      document.body.classList.add('lock');
      return () => {
        document.removeEventListener('keydown', onKeyDown);
        document.body.classList.remove('lock');
      };
    }
  }, [galleryModal]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <styled.SliderArrowBox>
        <Icon name={Name.GENERAL_SLIDER_ARROW} />
      </styled.SliderArrowBox>
    ),
    nextArrow: (
      <styled.SliderArrowBox $right>
        <Icon name={Name.GENERAL_SLIDER_ARROW} />
      </styled.SliderArrowBox>
    ),
  };

  const contactExpert = () => {
    localStorage.setItem(LocalKeys.SELECTED_EXPERT, expert.companyName);
  };

  const onGalleryImageClick = (index: number) => {
    setGalleryModal(true);
    sliderRef?.current?.slickGoTo(index, true);
  };

  const visibleGallery = expert.gallery.slice(0, 4);

  return (
    <Layout>
      <Breadcrumbs $hideOnLg links={[Links.EXPERTS, expert.companyName]} />
      <styled.ContentBox>
        <styled.StickyWrapper>
          <styled.StickyBox>
            <Card card={expert} $isSlug externalIndex="isSlug" />
          </styled.StickyBox>
        </styled.StickyWrapper>
        <styled.RightContent>
          <div>{expertDescription}</div>
          <div style={{ marginTop: '15px' }}>
            <styled.Title>Gallery</styled.Title>
            <styled.MobileVideosBox>
              <CustomSlider>
                {expert.gallery.map((galleryImage, index) => (
                  <div key={`video-${index}`}>
                    <GatsbyImage
                      image={getImage(galleryImage)}
                      alt={galleryImage.title}
                    />
                  </div>
                ))}
              </CustomSlider>
            </styled.MobileVideosBox>
            <styled.DesktopVideosBox>
              {visibleGallery.map((galleryImage, index) => (
                <div
                  key={`video-${index}`}
                  onClick={() => onGalleryImageClick(index)}
                >
                  <styled.GalleryImage
                    image={getImage(galleryImage)}
                    alt={galleryImage.title}
                    objectFit="contain"
                  />
                </div>
              ))}
              <styled.Overlay $active={galleryModal}>
                <styled.SliderBox ref={sliderBoxRef}>
                  <styled.CloseBox onClick={() => setGalleryModal(false)}>
                    <Icon name={Name.GENERAL_CLOSE} />
                  </styled.CloseBox>
                  <Slider {...settings} ref={sliderRef}>
                    {expert.gallery.map((galleryImage, index) => (
                      <div key={`video-${index}`}>
                        <styled.SliderImage
                          image={getImage(galleryImage)}
                          alt={galleryImage.title}
                          objectFit="contain"
                        />
                      </div>
                    ))}
                  </Slider>
                </styled.SliderBox>
              </styled.Overlay>
            </styled.DesktopVideosBox>
          </div>
          <div>
            <styled.ReviewsHeader>
              <div>
                <styled.ReviewsTitle>Reviews</styled.ReviewsTitle>
                <Rating
                  reviews={expert.reviews}
                  externalIndex="isHeader"
                  $isHeader
                />
              </div>
              <Button
                color={ButtonColors.SECONDARY}
                onClick={contactExpert}
                to={Links.TALK}
              >
                Write a review
              </Button>
            </styled.ReviewsHeader>
            <styled.ReviewsList>
              {expert.reviews ? (
                expert.reviews.map((review, index) => {
                  const date = new Date(review.date)
                    .toLocaleDateString()
                    .replaceAll('.', '/');
                  return (
                    <styled.ReviewContent key={`review-${index}`}>
                      <styled.ReviewHead>{review.title}</styled.ReviewHead>
                      <styled.ReviewRatingBox>
                        <Rating
                          review={review}
                          externalIndex={index}
                          style={{ marginTop: '0px' }}
                        />
                        <styled.RevieiwDate>{date}</styled.RevieiwDate>
                      </styled.ReviewRatingBox>
                      <styled.ReviewDescription>
                        {review.review.review}
                      </styled.ReviewDescription>
                      <styled.ReviewAuthor>
                        — {review.author}
                      </styled.ReviewAuthor>
                    </styled.ReviewContent>
                  );
                })
              ) : (
                <styled.Text>No reviews in the spotlight yet.</styled.Text>
              )}
            </styled.ReviewsList>
          </div>
        </styled.RightContent>
      </styled.ContentBox>
    </Layout>
  );
};
