import { Links, webappUrl } from '@/utils/constants';
import { removeSpaces } from '@/utils/functions';
import { PRODUCTS } from '../Products/ProductsInfo';
import { Name } from '../general';

export const SOCIALS = [
  {
    iconName: Name.SOCIALS_LINKEDIN,
    href: 'https://www.linkedin.com/company/codeplatform',
    text: 'LinkedIn',
  },
  {
    iconName: Name.SOCIALS_GITHUB,
    href: 'https://github.com/code-platform',
    text: 'GitHub',
  },
  {
    iconName: Name.SOCIALS_YOUTUBE,
    href: 'https://youtube.com/@codeplatform',
    text: 'Youtube',
  },
];

const productColumnLinks = PRODUCTS.map((product) => ({
  text: product.title,
  to: product.to ? product.to : `/${removeSpaces(product.title)}`,
  iconName: product.icon,
}));

export const COLUMNS = [
  { title: 'Features', links: productColumnLinks },
  {
    title: 'Resources',
    links: [
      { text: 'Blog', to: Links.BLOG },
      { text: 'Pricing', to: Links.PRICING },
      { text: 'Experts', to: Links.EXPERTS },
      {
        text: 'Sign In',
        to: `${webappUrl}/auth`,
      },
    ],
  },
  {
    title: 'Get in touch',
    links: [
      { text: 'Support', to: 'mailto:support@codeplatform.com' },
      { text: 'Contact Us', to: Links.TALK },
      { text: 'Investors', to: Links.INVESTORS },
      { text: 'Become a Partner', to: Links.PARTNERS_PROGRAM },
    ],
  },
];
