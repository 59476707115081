import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as text from '@/components/general/TextStyles';

export const ContentBox = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 90px;
  max-width: 1105px;
  width: 100%;
  margin: 96px auto 0;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    flex-direction: column;
    align-items: center;
    max-width: none;
    margin-top: 50px;
  }
`;

export const StickyWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const StickyBox = styled.div`
  position: sticky;
  top: ${(props) => props.theme.headerSize.heightDesktop + 50}px;
  left: 0px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    position: static;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 35px;
  max-width: 698px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    max-width: none;
    margin-top: 40px;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 15px;
  font-weight: ${(props) => props.theme.weights.bold};
`;

export const MobileVideosBox = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const DesktopVideosBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  & > div {
    width: calc((100% - 10px) / 2);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const GalleryImage = styled(GatsbyImage)`
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
`;

export const ReviewsTitle = styled(Title)`
  margin-bottom: 0;
`;

export const ReviewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  & > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

export const ReviewContent = styled.div`
  padding-bottom: 10px;
`;

export const ReviewsList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  ${ReviewContent}:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  }
`;

export const ReviewHead = styled.p`
  font-size: 14px;
  line-height: 20.3px;
  font-weight: ${(props) => props.theme.weights.semiBold};
`;

export const ReviewRatingBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  column-gap: 10px;
`;

export const RevieiwDate = styled.div`
  font-size: 13px !important;
  line-height: 150% !important;
  color: ${(props) => props.theme.colors.grayText};
  font-weight: ${(props) => props.theme.weights.regular};
`;

export const Text = styled.p`
  ${text.FS16}
  line-height: 24px;
`;

export const ReviewDescription = styled(Text)`
  margin-top: 8px;
  color: ${(props) => props.theme.colors.grayText};
`;

export const ReviewAuthor = styled(Text)`
  margin-top: 35px;
  color: ${(props) => props.theme.colors.grayText};
`;

interface IActive {
  $active?: boolean;
}

export const Overlay = styled.div<IActive>`
  position: fixed;
  width: 100vw !important;
  height: ${(props) =>
    `calc(100vh - ${props.theme.headerSize.heightDesktop}px)`};
  top: ${(props) => props.theme.headerSize.heightDesktop}px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 26px;
  opacity: ${(props: IActive) => (props.$active ? '1' : '0')};
  transition: opacity 0.4s ease;
  pointer-events: ${(props: IActive) => (props.$active ? 'auto' : 'none')};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    height: ${(props) =>
      `calc(100vh - ${props.theme.headerSize.heightMobile}px)`};
    top: ${(props) => props.theme.headerSize.heightMobile}px;
  }
`;

export const SliderArrowBox = styled.div<{ $right?: boolean }>`
  transform: translate(0, -50%)
    ${(props) => (props.$right ? 'rotate(180deg)' : 'rotate(0deg)')}!important;
  transform: ${(props) => (props.$right ? 'rotate(180deg)' : 'none')};
  padding: 10px !important;
  width: 60px !important;
  height: 60px !important;
  box-sizing: content-box !important;
  ${(props) => {
    if (props.$right) {
      return `
        right: 0px!important;
      `;
    }
    return `
        left: 0px!important;
      `;
  }}
  z-index: 1;
  &::before {
    display: none;
  }
  & svg {
    width: 60px !important;
    height: 60px !important;
    & path {
      stroke: #e2e2e2;
    }
  }
`;

export const SliderImage = styled(GatsbyImage)`
  max-width: 80vw;
  max-height: 70vh;
`;

export const SliderBox = styled.div`
  width: 100%;

  & .slick-track {
    display: flex !important;
    align-items: center;
  }

  & .slick-slide {
    & > div > div {
      display: flex !important;
      justify-content: center;
    }
  }
`;

export const CloseBox = styled.div`
  position: absolute;
  right: 28px;
  top: 28px;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  & svg {
    width: 30px;
    height: 30px;
    & path {
      stroke: #e2e2e2;
    }
  }
`;
