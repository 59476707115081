import styled from 'styled-components';

import * as text from '@/components/general/TextStyles';

export const Heading2 = styled.h2`
  font-size: 24px;
  line-height: 28.8px;
  margin-bottom: 15px;
  font-weight: ${(props) => props.theme.weights.bold};
  /* position: relative; */
`;

export const Heading3 = styled.h3`
  ${text.FS17_NOT_CHANGE}
  line-height: 20.4px;
  font-weight: ${(props) => props.theme.weights.bold};
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const Anchor = styled.div`
  position: absolute;
  left: 0;
  top: -${(props) => props.theme.headerSize.heightDesktop + 9}px;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}px) {
    top: -${(props) => props.theme.headerSize.heightMobile + 9}px;
  }
`;

export const Text = styled.p`
  ${text.FS16}
  line-height: 24px;
`;
