import React, { useEffect, useRef } from 'react';

import { Icon, Name } from '@/components/general';
import * as styled from './Rating.styled';

import { IReview } from '../../types';

function averageScores({ avg, n }, el) {
  return {
    avg: (el.rating + n * avg) / (n + 1),
    n: n + 1,
  };
}

interface IRating {
  $isSlug?: boolean;
  reviews?: IReview[];
  review?: IReview;
  $isHeader?: boolean;
  style?: React.CSSProperties;
  externalIndex: number | string;
}

export const Rating: React.FC<IRating> = ({
  $isSlug,
  $isHeader,
  reviews,
  review,
  style,
  externalIndex,
}) => {
  const initialVals = review ? { avg: review.rating, n: 1 } : { avg: 0, n: 0 };
  const avg = reviews
    ? reviews.reduce(averageScores, initialVals)
    : initialVals;
  const floor = Math.floor(avg.avg);
  const decimal = (avg.avg - floor) * 100;

  const starsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const stars = starsRef.current.childNodes;
    stars.forEach((star, index) => {
      const id = `star-fill-${externalIndex}-${index}`;
      const linearGradient = star.firstChild.firstChild.firstChild;
      (linearGradient as HTMLElement).id = id;
      const path = star.firstChild.lastChild;
      (path as HTMLElement).setAttribute('fill', `url(#${id})`);
      const stops = linearGradient.childNodes;
      if (index === floor) {
        (stops[1] as HTMLElement).setAttribute('offset', `${decimal}%`);
        (stops[2] as HTMLElement).setAttribute('offset', `${decimal}%`);
      }
      if (index > floor) {
        (stops[1] as HTMLElement).setAttribute('offset', '0%');
        (stops[2] as HTMLElement).setAttribute('offset', '0%');
      }
    });
  }, [starsRef]);

  return (
    <styled.RatingBox $isSlug={$isSlug} $isHeader={$isHeader} style={style}>
      <styled.RatingStars ref={starsRef}>
        {Array.from(Array($isHeader ? 1 : 5)).map((_, index) => (
          <Icon name={Name.GENERAL_REVIEW_STAR} key={`star-${index}`} />
        ))}
      </styled.RatingStars>
      {!!avg.n && (
        <>
          <span>{(Math.round(avg.avg * 10) / 10).toFixed(1)} </span>
          {!review && <span>({avg.n})</span>}
        </>
      )}
    </styled.RatingBox>
  );
};
