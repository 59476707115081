/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

import { BorderLinkOut } from '@/components/general';

import * as styled from './Options.styled';

export const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <styled.Text>{children}</styled.Text>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <styled.Heading2>
        {/* <styled.Anchor id={removeSpaces(children.toString())} /> */}
        {children}
      </styled.Heading2>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <styled.Heading3>
        {/* <styled.Anchor id={removeSpaces(children.toString())} /> */}
        {children}
      </styled.Heading3>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <BorderLinkOut href={node.data.uri} rel="nofollow">
        {children}
      </BorderLinkOut>
    ),
  },
};
