import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as text from '@/components/general/TextStyles';

export const LinkAnchor = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 32px;
`;

export const Top = styled.div`
  position: relative;
  width: 100%;
  height: 150px;
`;

export const TopImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 0;
`;

export const Role = styled.div`
  position: absolute;
  left: 20px;
  top: 20px;
  padding: 0 15px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: rgba(1, 1, 1, 0.1);
  backdrop-filter: blur(50px);
  color: ${(props) => props.theme.colors.white};
  text-transform: uppercase;
  ${text.FS14}
  font-weight: ${(props) => props.theme.weights.bold};
`;

export const Logo = styled.img`
  position: absolute;
  bottom: -20px;
  /* bottom: 40px; */
  left: 20px;
  width: 60px;
  height: 60px;
  border-radius: 16px;
`;

export const Bottom = styled.div`
  margin-top: 12px;
  margin-bottom: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 170px);
`;

export const Name = styled.h3`
  font-size: 24px;
  line-height: 120%;
  font-weight: ${(props) => props.theme.weights.semiBold};
`;

export const Agency = styled.div`
  ${text.FS16}
  line-height: 24px;
  color: ${(props) => props.theme.colors.grayText};
  font-weight: ${(props) => props.theme.weights.semiBold};
  margin-top: 5px;
`;

export const Description = styled.p<{ lines?: number }>`
  ${text.FS16}
  line-height: 24px;
  color: ${(props) => props.theme.colors.grayText};
  margin-top: 10px;
  flex-grow: 1;
  overflow: hidden;
  -ms-line-clamp: ${(props) => props.lines};
  -webkit-line-clamp: ${(props) => props.lines};
  line-clamp: ${(props) => props.lines};
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;
`;

export const ButtonBox = styled.div`
  display: none;
  column-gap: 18px;
  margin: 25px 0 -8px;
  & > button,
  a {
    width: 100%;
  }
`;

interface IWrapper {
  $isSlug?: boolean;
}

export const Wrapper = styled.div<IWrapper>`
  border-radius: 32px;
  min-height: 413px;
  width: 353px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  background-color: ${(props) => props.theme.colors.white};
  position: relative;
  overflow: hidden;
  ${(props) => {
    if (props.$isSlug) {
      return `
      height: fit-content;
      width: 317px;
      min-width: 317px;
      border-radius: 16px;
      min-height: 0px;
      ${ButtonBox} {
          display: flex;
        }
      `;
    }
  }}

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    ${(props) => {
      if (props.$isSlug) {
        return `
        width: 100%;
        min-width: 0px;
      `;
      }
    }}
  }
  @media (max-width: ${(props) => props.theme.breakpoints.xs}px) {
    width: 100%;
  }
`;
